import React from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { handleEncoding } from "../../utils/handleEncoding";

function Product({
  popular = null,
  title,
  image,
  productPath,
  productPrice,
  productOldprice,
  productId,
  productLink,
}) {
  return (
    <div className="p-4 w-1/2 md:w-1/3 lg:w-1/4 mb-4 bg-white">
      <div className="product-thumbnail mb-2">
        {image ? (
          <LazyLoadImage alt={title} src={image} />
        ) : (
          <div className="border w-full h-full flex items-center justify-center">
            <h3>Intet billede</h3>
          </div>
        )}
      </div>
      <Link to={productPath}>
        <h2 className="font-bold" style={{ minHeight: 70 }}>
          {handleEncoding(title)}
        </h2>
      </Link>
      <div className="">
        {productOldprice && Number(productOldprice) > Number(productPrice) ? (
          <p className="opacity-60 line-through">{productOldprice} DKK</p>
        ) : (
          <p>&nbsp;</p>
        )}
        <p className="mb-4 font-bold">{productPrice} DKK</p>
      </div>
      <Link
        to={popular ? productPath : `/redirect/${productId}`}
        className="btn font-bold text-center green block w-full"
      >
        Læs mere
      </Link>
    </div>
  );
}

export default Product;
